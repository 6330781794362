class nav {
    constructor() {
    	this.mainNavLinks = $(".js_main_nav").find("a[data-megamenu-id]");
    	this.megaMenuList = $(".js_mega_menu");
        this.mobileNav = $(".js_mobile_nav");
        this.mobileNavClose = $(".js_mobile_nav_close");
        this.mobileNavOpen = $(".js_mobile_nav_open");
    }

    navUpdates() {
    	let navLinks = this.mainNavLinks,
    		menuList = this.megaMenuList;

    	if(navLinks.length > 0) {
    		for(var link of navLinks) {
    			let $handle = $(link),
    				menuId = $handle.attr("data-megamenu-id"),
    				$target = menuList.filter("#"+menuId);
    			
    			if($target.length == 1) {
					$target.hover(
						function() {
							$handle.addClass("is_open");
							$target.addClass("is_active");
						}, function() {
							$handle.removeClass("is_open");
							$target.removeClass("is_active");
						}
					);
					$handle.hover(
						function() {
							$target.addClass("is_active");
						}, function() {
							$target.removeClass("is_active");
						}
					);
    			}
    		}
    	}

        let closeBtn = this.mobileNavClose,
            openBtn = this.mobileNavOpen,
            mNav = this.mobileNav;

        openBtn.click(function(){console.log("open nav");mNav.addClass("is_open");});
        closeBtn.click(function(){console.log("close nav");mNav.removeClass("is_open");});
    }
};

export default nav;