class matchHeight {
    constructor() {
        this.elemList = $(".js_match_height");
    }

    match() {
        let matchList = this.elemList;
        if(matchList.length > 0) {
            matchList.each(function(){
                let attr = $(this).attr('data-matchheight'),
                    list = ((typeof attr !== typeof undefined && attr !== false) ? $(this).find(attr) : $(this).find('> li')),
                    highestBox = 0;

                list.height("auto");
                var windowWidth = $(window).width();
                if(list.length > 0 && windowWidth > 768) {
                    list.each(function() {
                        if($(this).height() > highestBox) {
                            highestBox = $(this).height();
                        }
                    });
                    list.height(highestBox);
                }
            });
        }
    }
};

export default matchHeight;