class gaTrackPdf {
    constructor() {
        this.elemList = $("a[href$='.pdf']");
    }

    trackPdf() {
        let pdfLinks = this.elemList;
        if(pdfLinks.length > 0) {
            pdfLinks.on("click", function(){
                ga('send', 'event', 'Download PDF', 'Download', $(this).attr("href"));
            });
        }
    }
};

export default gaTrackPdf;