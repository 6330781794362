// Include jquery to use in project
window.$ = window.jQuery = require('./_libs/jquery');

// Import all modules
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesLoaded';
import mainNav from './_modules/_m_nav';
import matchHeight from './_modules/_m_match_height';
import gaTrackPdf from './_modules/_m_ga_track_pdf';

// Nav related changes
let nav = new mainNav();
nav.navUpdates();

// Match height related changes
let mHeight = new matchHeight();
$(document).ready(function() {
	$(document).imagesLoaded( function() {
		mHeight.match();
		$(window).resize(function() {
			mHeight.match();
		});
	});
});

// Masonry layout
imagesLoaded.makeJQueryPlugin( $ );
$(document).ready(function() {
	var $masonary = $(".js-li-masonry");
	if($masonary.length > 0) {
		$masonary.imagesLoaded( function() {
			const grid = document.querySelector('.js-li-masonry')
			const msnry = new Masonry(grid, {
				itemSelector: 'li',
				columnWidth: 2,
				gutter: 0,
				transitionDuration: 0,
				initLayout: false
			})

			msnry.once('layoutComplete', () => {
				grid.classList.add('load')
			})

			msnry.layout();
		});
	}
});

// Google PDF Tracking
let trackPdf = new gaTrackPdf();
trackPdf.trackPdf();